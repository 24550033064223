import styled from 'styled-components';

const RegularFont = 'Rubik';
const MediumFont = 'Rubik-Medium';
const MainContentContainer = styled.div`
  margin: 0px 20px 20px 15px;
  width: calc(100% - 34px);
  max-width: 1246px;
  margin: auto;
  font-family: ${RegularFont};
`;
const Activities = styled.div`
  float: left;
  width: 69.1%;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 767px) and (max-width: 1200px) {
    width: 66%;
    width: 69.1%;
  }
`;

const SidebarContainer = styled.div`
  float: right;
  width: 30.9%;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Container = styled.div`
  display: inline-block;
  width: ${({ behaviorChallenge }) => behaviorChallenge ? '100%' : 'calc((100%/3) - 80px);'};
  background-color: white;
  float: left;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  margin-right: 20px;
  margin-top: ${({ behaviorChallenge }) => !behaviorChallenge && '20px'};
  height: ${({ fixedHeight }) => fixedHeight ? '450px' : 'auto'};

  &:last-child {
    margin-right: 0;
  }
  
  @media(max-width: 1200px) {
    width: 100%;
    height: auto;
  }
`;
const ContainerV2 = styled(Container)`
  width: 100%;
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};
  background: ${({background}) => background ? '#FFFFFF' : 'transparent'};
  margin-top: 0px;
  box-shadow: none;
  height: ${({height}) => height ? height : 'unset'};
  margin-bottom: ${({background}) => background && '10px'};
  padding-bottom: ${({background}) => background && '30px'};
  border-radius: ${({background}) => background && '6px'};
`;
const SlideShowContainer = styled.div`
   @media(min-width: 1200px) {
    display: none;
   }
  .slick-slide.carousel-item {
    opacity: 0.5;
    &.slick-active {
      opacity: 1;
      &.slick-center {
        opacity: 0.5;
      }
    }
  }
  .slick-slider {
    width: 100%;
    min-width: 337px;
    max-width: 400px;
    margin: 35px 0 15px;
    .slick-slide{
      margin: 0px 2px 0px 0px;
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      left: -10px;
      position: absolute;
      top: 50%;
      right: -52px;
      z-index: 1;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.375rem;
    }
    .slick-prev:before {
      font-family: "Rubik";
      font-size: 42px;
      line-height: 1;
      color: #4798D9;
      margin: 0 15px;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: left .3s ease;
      }
      .slick-prev:before {
        content: "‹";
      }
      [dir="rtl"] .slick-prev:before {
        content: "›";
      }
    .slick-next {
        left: unset;
        position: absolute;
        top: 50%;
        right: -15px;
        z-index: 1;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.375rem;
      }
      .slick-next:before {
        content: "›";
        font-family: "Rubik";
        font-size: 42px;
        line-height: 1;
        color: #4798D9;
        margin: 0 18px;
      }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;
const  SlideShowCard = styled.div`
  width: ${({fullWidth}) => fullWidth ? 'calc((100% - 10px) / 3)' : '95%'};
  border-radius: 6px;
  display: flex;
  margin: 0 7px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 200px;
  .bg-opacity {
    position: absolute;
    height: 200px;
    width: ${({fullWidth}) => fullWidth ? '100%' : '95%'};
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    @media (max-width: 600px){
      width: 100%;
    }
  }
  .slideName{
    position: absolute;
    font-family: ${MediumFont}
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    text-transform: capitalize;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 81%;
    text-align: center;
  }
  @media (max-width: 600px){
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  width: ${({fullWidth}) => fullWidth ? '100%' : '95%'};
  display: inline-block;
  position:relative
  height: 200px;
  > img {
    cursor: default
    height: 200px;
    border-radius: 6px;
    width: 100%;
  }
  @media (max-width: 600px){
    width: 100%;
  }
`;
const MyFocusContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div: first-child {
    margin-right: 45px;
  }
  > div{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 64px;
    margin-right: 10px;
    & > div:last-child {
      margin-right: 0px;
    }
    >img{
      width: 48px;
      height: 48px;
      cursor: pointer; 
    }
    > div {
      color: rgba(38, 38, 43, 0.7);
      font-family: ${MediumFont};
      letter-spacing: 1px;
      text-align: center;
      margin: 10px 0;
      min-height: 32px;
      font-size: 12px;   
      padding: 2px;
    }
  }
`;
const DailyTipHeader = styled.div`
  color: ${({color}) => color ? '#8BBD53' : '#FF6D4A'};
  font-family: ${MediumFont};
  font-size: 18px;
  padding: 25px 30px 0px 30px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`;
const WellnessGoalContainer = styled.div`
  width: ${({width}) => width ? '100%' : 'calc((100%/3) - 14px)'};
  float: left;
  background-color: #FFFFFF;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: ${({width})=> width ? '0px' : '20px'};
`;
const SlideDisabled = styled.div`
  display: flex;
  margin: 35px -7px 15px -7px;
  @media(max-width: 1200px) {
    display: none;
  }
`;
const ConnectDevice = styled.div`
width: 100%;
height: 50px;
margin-bottom:${({margin})=> margin ? '25px' : '0px'};
> button {

  width: 100%;
  height: 100%;
  font-family: ${MediumFont};
  font-size: 15px;
  background-color: rgb(253,113,117);
  color: #FFF;
  padding: 3px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: stretch;
  outline: none;
  margin-top: 25px;
  > img {
    width: 44px;
    height: 44px;
    margin-right: 0px;
    float: left;
  }
  > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
    width: 44px;
    height: 44px;
    margin-right: 0px;
    float: left;
  }
    > span {
      font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;

const WrapperCard = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
padding-top: 25px;
`;

const CardContainer = styled.div`
width: 100%;
border-radius: 5px;
max-width: 1246px;
background:${({color})=> color ? color : ''};
padding: 25px;
margin-bottom: 25px;
`;

const Header = styled.div`
width: 100%;  
height: 35px;
border-radius: 6px 6px 0px 0px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin-top:${({marginTop})=> marginTop ? marginTop : '25px'};
    padding-bottom: 15px;
    > span: first-child {
      font-family: Rubik;
      font-weight: 500;
      font-size: 18px;
      color: #0D4270;
      line-height: 24px;
      float: left;
      text-transform: capitalize;
    font-size: 18px;
    color: #0D4270;
    font-family: Rubik-Medium;

			@media (max-width: 302px){
				margin-top: -10px;
			}
  
    }
  
    > span: last-child {
      float: right;
      font-family: Rubik;
      font-weight: 500;
      font-size: 14px;
      color: #0D4270;
			margin-top: 5px;
			display: none;

			@media (max-width: 302px){
				margin-top: -5px;
			}
    }
  }
`;
const DailyActivityParent = styled.div`
width: calc((100%/2) - 25px);
&:last-child{
  width: calc((100%/2) - 12.5px);
}
&:last-child{
  width: calc((100%/2) - 0px);
  padding-right: 25px;
}

@media(max-width: 1100px){
  width: calc((100%/2) - 0px);
  &:last-child{
    width: calc((100%/2) - 12.5px);
  }
}
@media(max-width: 1000px){
  min-width: 326px;
  width: 45%;
}
@media(max-width: 700px){
  width: 100%;
}
`;
const DasboardSubParent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 25px;
  @media(max-width: 1100px){
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const ExploreMain = styled.div`
width: calc(100% - 25px);
margin-right: 25px;
`;

const ExploreHeading = styled.div`
width: 100%;
margin-bottom: 15px;

font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 21px;
color: #0D4270;
`;

const ExploreContainer = styled.div`
width: 100%;
display: block;
.main-container {
  display: flex;
  width: 100%;
  margin-bottom 15px;
  .wellnes-container {
    ${'' /* width: 65.2%; */}
    ${'' /* max-width: 545px; */}
    display: block;

    .wellness-first-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom 15px;
      .wellness-first-card {
        width: 48.63%;
        ${'' /* height: 230px; */}
        background: #FD7175;
        cursor: pointer;
        border-radius: 6px;
        .wellness-card-content{
          width: 100%;
          display: block;
          .wellness-text {
            font-family: 'Rubik-Medium';
            font-size: 18px;
            line-height: 21px;
            color:#fff;
            margin-top: 25px;
            text-align: center;
          }
          .wellness-image {
            width: 86%;
            ${'' /* height: 130.85px; */}
            margin: auto;
            margin-top: 36px;
            >img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .wellness-second-row {
      width: 100%;
      background: rgba(246, 180, 121, 1);
      cursor: pointer;
      display: flex;
      height: 100px;
      border-radius: 6px;
      .wellness-text {
        width: 50%;
        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 21px;
        color:#fff;
        text-align: center;
        display: flex;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5%;
      }
      .wellness-image {
        width: 50%;
        display: flex;
        .image-container {
          width: 35.5%;
          height: 84.94px;
          margin: auto;
          margin-left: 10%;
          >img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  }
  .challenge-container {
    width: 100%;
    padding-left 15px; 

    .challenge-first-row{
      background: linear-gradient( 180deg,#006FBC 0%,#7F43FF 100%);
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 6px;
      .challenge-text {
        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 21px;
        color:#fff;
        padding-top: 25px;
        text-align: center;
      }
      .challenge-image {
        display:flex;
        justify-content:center;
        width: 89%;
        height: 289px;
        margin: auto;
        margin-top: 7px;
        >img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.event-container {
  width: 100%;
  height: 50px;
  background: rgba(159, 201, 137, 1);
  display: flex;
  height: 118px;
  cursor: pointer;
  border-radius: 6px;
  .event-text {
    width: 50%;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 21px;
    color:#fff;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10%;
  }

  .event-image {
    display: flex;
    width: 50%;
    margin: auto;
    padding-left: 10%;
    .image-container{
      width: 40.5%;
      height: 104.12px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
`;

const TopBar = styled.div`
width: 100%;
border-radius: 6px;
max-width: 1246px;
background-image:${({image})=> image ? image : 'red'};
background-size : cover;
background-repeat : no-repeat;
background-size: 100% 100%;

padding: 28px;
margin-top: 25px;

>div{
  width: 100%;
  display: flex;
  justify-content: center;
.time{
  font-family: Rubik;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
.name{
  font-family: 'Rubik-Medium';
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}}
`;

const ActiveSurveyCard = styled.div`
width: 100%;
height: 80px;
margin-bottom:25px;
display: flex;
background-image:${({image})=> image ? image : 'red'};
background-size : cover;
background-repeat : no-repeat;
background-size: 100% 100%;
.wrapper{
  display: flex;
  margin:auto;
  width: 1246px;
  justify-content: center;
}
.message{
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: white;
  padding-right: 25px;
  align-items:center;
  display: flex;
  justify-content: flex-end;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.button{
  height: 40px;
  min-width: 250px;
  padding: 0px 10px;
  background: white;
  display:flex;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color:rgb(127,67,255);
  border-radius:3px;
  cursor:pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-right:25px;
}
`;

export { MainContentContainer, Activities, Container, SidebarContainer, SlideShowContainer, ImageContainer, SlideShowCard, ContainerV2, MyFocusContainer, DailyTipHeader, WellnessGoalContainer, SlideDisabled, ConnectDevice, WrapperCard, CardContainer, Header, DailyActivityParent, DasboardSubParent, ExploreMain, ExploreHeading, ExploreContainer, TopBar, ActiveSurveyCard};
